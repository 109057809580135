* {
  box-sizing: border-box;
}

body {
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Helvetica, Arial, sans-serif,
    Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol;
  line-height: 1.6;
  user-select: none;
  background-color: #f9f8f6;
}

h1,
h2 {
  margin-top: 1em;
  margin-bottom: 0.75em;
  line-height: 1.2;
}

h1 {
  font-size: 2em;
}

h2 {
  font-size: 1.5em;
}

hr {
  width: 100%;
  height: 2px;
  margin: 3em 0;
  background-color: #000;
  border: 0;
  opacity: 0.1;
}

input {
  font: inherit;
  appearance: none;
  background-color: transparent;
  border: 0;
}

.flex {
  display: flex;
}

// stylelint-disable-next-line
.w-1\/2 {
  width: 50%;
}

.main {
  margin: 4em auto 8em;
  padding-right: 1em;
  padding-left: 2em;
}

.content {
  max-width: 600px;
}

.items {
  display: flex;
  flex-wrap: wrap;
}

.item {
  width: 100%;
  max-width: 500px;
  padding-right: 1rem;
  padding-bottom: 2rem;
}

*:focus {
  outline: none;
}

.signature__meta {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 1em;
}

.signature__label {
  margin: 0;
  font-size: 1.4em;
}

.signature__btn {
  padding: 0.5em 1em;
  font: inherit;
  color: #fff;
  cursor: pointer;
  -webkit-appearance: none;
  background-color: #222;
  border: 0;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}

.signature__btn:hover {
  background-color: #333;
}

.signature__btn:active {
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
  transform: translate(0, 2px);
}

.signature__overlay {
  position: relative;
}

.signature__overlay::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  cursor: pointer;
  background-color: #f00;
  opacity: 0;
}

.signature__content {
  padding: 1em 1em 0;
  cursor: pointer;
  background-color: #fff;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  transition: box-shadow 1s cubic-bezier(0, 0.75, 0.25, 1);

  &:focus {
    outline: none;
    box-shadow: 0 8px 20px rgba(0, 0, 0, 0.2) !important;
  }

  .signature__overlay:hover & {
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.15);
  }
}

.signature__content::selection {
  color: inherit;
  text-shadow: none;
  background: transparent;
}

.category:not(:has(.item:not([style*='none']))) {
  display: none;
  outline: 1px solid blue;
}
